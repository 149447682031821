import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import TableComponent from "../../components/Tables";
import { TableCell, TableRow } from "@mui/material";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { STATE } from "../../constants";
import { useNavigate } from "react-router";
import { Button } from "primereact/button";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";

const WalletLedgerAdjustment = () => {
  const [loader, setLoader] = useState(true);

  const user = useSelector((state: RootState) => state?.authUser?.userData?.user)
  const [viewFullAdjustmentData, setvViewFullAdjustmentData] = useState<any>([]);
  const [viewPartialAdjustmentData, setviewPartialAdjustmentData] = useState<any>([]);

  const [viewFullAdjustmentDataState, setvViewFullAdjustmentDataState] = useState(STATE.DEFAULT_STATE);
  const [viewPartialAdjustmentDataState, setvViewPartialAdjustmentDataState] = useState(STATE.DEFAULT_STATE);

  const [formData,setFormData]=useState(null)
 const [permission, setPermission] = useState<any>({});
  const [type,setType]=useState('full')
  const navigate = useNavigate();
  const [fullAdjustConfirmVisible, setFullAdjustConfirmVisible] =
  useState<boolean>(false);
  const [partiallAdjustConfirmVisible, setPartialAdjustConfirmVisible] =
  useState<boolean>(false);

const acceptFullAdjustment = () => {

  let payload=formData;
  ApiPost('walletLedger/full-adjust-wallet-ledger',payload).then((res:any)=>{
 if(res.status == 200){
  toast.success('Case Fully Adjusted', {
    position: "top-right",
    theme: "colored",
  });
  getFullAdjustmentWalletLedger()
  setFormData(null)

 }
  }).catch(()=>{

  })
};

const rejectFullAdjustment = () => {
  setFullAdjustConfirmVisible(false);
};

const acceptPartialAdjustment = () => {

  let payload=formData;
  ApiPost('walletLedger/partial-adjust-wallet-ledger',payload).then((res:any)=>{
 if(res.status == 200){
  toast.success('Case Fully Adjusted', {
    position: "top-right",
    theme: "colored",
  });
  getPartialAdjustmentWalletLedger()
  setFormData(null)
 }
  }).catch(()=>{

  })
};

const rejectPartialAdjustment = () => {
  setPartialAdjustConfirmVisible(false);
};

  const goBack = () => {
    navigate(-1);
  };
  let viewFullAdjustmentDataColumns = [
    { id: "username", label: "Dsa Name" },
    { id: "borrower_name", label: "Borrower Name" },
    { id: "bank_name", label: "Bank" },
    { id: "product", label: "Product" } ,
    { id: "gross_payout", label: "Gross Payout" } ,
    { id: "month", label: "Month" },
    { id: "year", label: "Year" },
    { id: "Action", label: "Action" },

  ];
  
  const getMonthString = (month: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(month, 10) - 1]; // Subtract 1 because array is zero-based
  };

  useEffect(() => {
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.STAFF]: userPermission(user, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(user, PERMISSION_TYPE.STAFF_OPS),
      [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(user, PERMISSION_TYPE.BOOKING_PARTNER),

    };
    setPermission(tempPermission);
  }, [user]);
  useEffect(() => {
    Promise.all([getFullAdjustmentWalletLedger(),getPartialAdjustmentWalletLedger()])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, []);
  const getFullAdjustmentWalletLedger=()=>{
    setLoader(true)
    ApiGet(`walletLedger/get-full-adjustment-wallet-list`)
        .then((res: any) => {
          let finalData = res.data.result.map((item: any, index: number) => {
            return {
              borrower_name: item?.borrower_name,
              bank_name: item?.bank_name,
              product: item?.product,
              year: item?.year,
              month: item?.month,
              user_id: item?.user_id,
              dsadisbursement_id: item?.dsadisbursement_id,
              username:item?.username,
              logincodelist_id:item.logincodelist_id,
              payout:item.payout,
              tamount:item.tamount,
              gross_payout:item.gross_payout
            };
          });
          setvViewFullAdjustmentData(finalData)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
  
        });
    }

    const getPartialAdjustmentWalletLedger=()=>{
      setLoader(true)
      ApiGet(`walletLedger/get-partial-adjustment-wallet-list`)
          .then((res: any) => {
            let finalData = res.data.result.map((item: any, index: number) => {
              return {
                borrower_name: item?.borrower_name,
                bank_name: item?.bank_name,
                product: item?.product,
                year: item?.year,
                month: item?.month,
                user_id: item?.user_id,
                dsadisbursement_id: item?.dsadisbursement_id,
                username:item?.username,
                logincodelist_id:item.logincodelist_id,
                payout:item.payout,
                tamount:item.tamount,
                gross_payout:item.gross_payout

              };
            });
            setviewPartialAdjustmentData(finalData)
            setLoader(false)
          })
          .catch((error) => {
            setLoader(false)
    
          });
      }

  return (
    <div className="overflow-x-hidden p-6">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {type== 'full' &&<div className="flex flex-wrap gap-5  justify-between items-center mb-5">
            
            {/* Start:: view Modal */}
            
            <div className="md:flex w-full lg:w-[unset] items-center  md:space-x-9 space-y-5 md:space-y-0">
            <button  className="mt-2" onClick={goBack}> <i className="pi pi-arrow-circle-left" style={{ fontSize: '2.0rem' }}></i> </button>
              <h1 className="text-[22px] text-light-gray font-semibold">
                Disbursement List can be Fully adjusted
              </h1>
            </div>
            <TableComponent
              rows={viewFullAdjustmentData}
              columns={ viewFullAdjustmentDataColumns }
              state={viewFullAdjustmentDataState}
              renderColumn={(column: any) => {
                return (
                  <TableCell
                    align="center"
                    className="!bg-gray-200"
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                );
              }}
              renderRow={(row: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell align="center" component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.borrower_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.bank_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.product}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.gross_payout}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {getMonthString(row?.month)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.year}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                    <Button label="Adjust" onClick={()=>{setFullAdjustConfirmVisible(true)
                      setFormData(row)
                    }}/>

                    </TableCell>
                  </TableRow>
                );
              }}
            />
            {/* End:: view Modal */}
          
</div>  

}
          {  type== 'partial'&&  <div className="flex flex-wrap gap-5  justify-between items-center mb-5">

          <div className="md:flex w-full lg:w-[unset] items-center md:space-x-9 space-y-5 md:space-y-0">
            <button  className="mt-2" onClick={goBack}> <i className="pi pi-arrow-circle-left" style={{ fontSize: '2.0rem' }}></i> </button>
              <h1 className="text-[22px] text-light-gray font-semibold">
                Disbursement List can be partially adjusted
              </h1>
            </div>
            <TableComponent
              rows={viewPartialAdjustmentData}
              columns={ viewFullAdjustmentDataColumns }
              state={viewPartialAdjustmentDataState}
              renderColumn={(column: any) => {
                return (
                  <TableCell
                    align="center"
                    className="!bg-gray-200"
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                );
              }}
              renderRow={(row: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell align="center" component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.borrower_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.bank_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.product}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.gross_payout}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {getMonthString(row?.month)}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.year}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                    <Button label="Adjust" onClick={()=>{setPartialAdjustConfirmVisible(true)
                      setFormData(row)
                    }}/>

                    </TableCell>
                  </TableRow>
                );
              }}
            />
                        {/* End:: view Modal */}
          </div>}
          {type == 'partial' &&<div className="flex w-full lg:w-[unset] items-center lg:justify-end md:space-x-9 space-y-5 md:space-y-0">
          <Button iconPos="right" icon='pi pi-arrow-circle-left' label="Back" onClick={()=>{
                      setType('full')
                    }} /></div> } 
          {type == 'full' &&<div className="flex w-full lg:w-[unset] items-center lg:justify-end md:space-x-9 space-y-5 md:space-y-0">
          <Button iconPos="right" icon= 'pi pi-arrow-circle-right' label='Next' onClick={()=>{
                      setType('partial')
                    }} /></div>}                    
          <ConfirmDialog
            // group="declarative"
            visible={fullAdjustConfirmVisible}
            onHide={() => setFullAdjustConfirmVisible(false)}
            message="Are you sure you want to Adjust this case ?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={acceptFullAdjustment}
            reject={rejectFullAdjustment}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />          
          <ConfirmDialog
            // group="declarative"
            visible={partiallAdjustConfirmVisible}
            onHide={() => setPartialAdjustConfirmVisible(false)}
            message="Are you sure you want to Adjust this case ?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={acceptPartialAdjustment}
            reject={rejectPartialAdjustment}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />
        </>
      )}
    </div>
  );
};

export default WalletLedgerAdjustment;
