import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom';
import ConnectorLisr from '../containers/connector/ConnectorList';
import Dashboard from '../containers/dashboard/Dashboard';
import DisbursementData from '../containers/disbursement/DisbursementData';
import KycStatus from '../containers/kyc/KycStatus';
import LoginCodeList from '../containers/login/LoginCodeList';
import PayoutGrid from '../containers/payout/PayoutGrid';
import SmList from '../containers/sm/SmList';
import UserReffered from '../containers/user/UserReffered';
import Login from './login';
import AppRoutes from './../routes/Routes'
import Register from './register';
import ForgotPassword from './forgotPassword';
import KycAdmin from '../containers/adminkyc/KycAdmin';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import ForgotPasswordLink from './forgotPassowrdLink';
import STORAGEKEY from '../config/APP/app.config';
import Storage from '../service/storage';
import Staff from './../containers/staff/staff';
import SingleUserList from './../containers/userdatabase/SingleUserList';
import IncentiveCalculation from '../containers/incentive/IncentiveCalculation';
import LoginCredentials from '../containers/login_credentials/login_credentials';
import Miscellaneous from '../containers/miscellaneous/miscellaneous';
import 'react-datepicker/dist/react-datepicker.css';
import BasicDetails from '../containers/basicdetails/BasicDetails';
import SingleIncentiveCalculation from './../containers/incentive/SingleIncentiveCalculation';
import UserBasicDetails from './../containers/basicdetails/UserDetails';
import PrivacyPolicy from './privacyPolicy';
import UserPayout from '../containers/userdatabase/UserPayout';

import BankDisbursementData from '../containers/bankdisbursement/bankdisbursement';
import ReverseMIS from '../containers/reverseMis/reverseMis';
import UserDatabase from '../containers/userdatabase/UserDatabase';
import ReverseMisDataModal from '../containers/reverseMis/reverseMisDataModal';
import BankDisbursementDataModal from '../containers/bankdisbursement/bankdisbursementDataModal';
import ReviewLosses from '../containers/reviewlosses/reviewloss';
import CompanyPayoutGrid from '../containers/companyPayout/CompanyPayoutGrid';
import BookingPartner from '../containers/booking_partner/BookingPartner';
import CampaignDetails from '../containers/campaigndetails/campaigndetails';
import CampaignDetailsDataModal from '../containers/campaigndetails/campaignDetailsDataModal';
import WalletLedgerAdjustment from '../containers/bankdisbursement/walletledgeradjustment';
import WalletLedgerModal from '../containers/userdatabase/WalletLedgerModal'
import ReconcileCaseData from '../containers/reconcilecasedata/ReconcileCaseData';

const Index = () => {

    const userdata = Storage.get(STORAGEKEY.token)
    let navigate = useNavigate();
    const authpathname = ["/login", "/register", "/forgotpassword", "/forgotpass","/privacypolicy"]
    const location = useLocation();

    useEffect(() => {
        if (location.pathname == "/forgotpass") {

        }else if (location.pathname == "/privacypolicy" && !userdata) {
            navigate("/privacypolicy", { replace: true });
        }
        else if (!userdata) {
            navigate("/login", { replace: true });
        } else {
            if (authpathname.includes(location.pathname)) {
                navigate("/", { replace: true });
            }
        }
    }, [userdata])


    return (
        <>

            <Routes>
                {(userdata) &&
                    <Route element={<AppRoutes />}>

                        <Route path="/" element={<Dashboard />} />
                        <Route path="/userpayout/:id" element={<UserPayout />} />
                        <Route path="/userdatabase" element={<UserDatabase />} />
                        <Route path="/userdatabase/wallet/:id" element={<WalletLedgerModal />} />
                        <Route path="/userdatabase/:id" element={<SingleUserList />} />
                        <Route path="/userrefferd" element={<UserReffered />} />
                        <Route path="/incentive" element={<IncentiveCalculation />} />
                        <Route path="/kycstatus" element={<KycStatus />} />
                        <Route path="/logincode" element={<LoginCodeList />} />
                        <Route path="/payoutgrid" element={<PayoutGrid />} />
                        <Route path="/payoutgrid/:id" element={<PayoutGrid />} />
                        <Route path="/smlist" element={<SmList />} />
                        <Route path="/connector" element={<ConnectorLisr />} />
                        <Route path="/disbursement" element={<DisbursementData />} />
                        <Route path="/reviewloss" element={<ReviewLosses />} />
                        <Route path="/companyPayoutGrid" element={<CompanyPayoutGrid />} />
                        <Route path="/bookingPartner" element={<BookingPartner />} />
                        <Route path="/campaignDetails" element={<CampaignDetails />} />
                        <Route path="/campaignDetails/:id" element={<CampaignDetailsDataModal />} />
                        <Route path="/reconcilecasedata" element={<ReconcileCaseData />} />

                        <Route path="/bankdisbursement" element={<BankDisbursementData />} />
                        <Route path="/bankdisbursement/:id" element={<BankDisbursementDataModal />} />
                        <Route path="/walletledgeradjustment" element={<WalletLedgerAdjustment />} />

                        <Route path="/reversemis" element={<ReverseMIS />} />
                        <Route path="/reversemis/:id" element={<ReverseMisDataModal />} />

                        <Route path="/kycadmin" element={<KycAdmin />} />
                        <Route path="/staff" element={<Staff />} />
                        <Route path="/staff/:id" element={<SingleIncentiveCalculation />} />
                        <Route path="/login_credentials" element={<LoginCredentials />} />
                        <Route path="/miscellaneous" element={<Miscellaneous />} />
                        <Route path="/basicdetails" element={<BasicDetails />} />
                        <Route path="/userdetails" element={<UserBasicDetails />} />
                    </Route>
                    //   :
                    // <Route path="/" element={<Login />} /> 
                }

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/forgotpass" element={<ForgotPasswordLink />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            </Routes>
        </>
    )
}

export default Index;