import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import TableComponent from "../../components/Tables";
import { TableCell, TableRow } from "@mui/material";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { STATE } from "../../constants";
import { useNavigate } from "react-router";
import { Button } from "primereact/button";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import moment from 'moment';

const WalletLedgerAdjustment = () => {
  const [loader, setLoader] = useState(true);
  let { id } = useParams();

  const user = useSelector((state: RootState) => state?.authUser?.userData?.user)
  const [viewData, setViewData] = useState<any>([]);

  const [viewDataState, setvViewDataState] = useState(STATE.DEFAULT_STATE);

 const [permission, setPermission] = useState<any>({});
  const navigate = useNavigate();
  
  const goBack = () => {
    navigate(-1);
  };
  let viewDataColumns = [
    { id: "reason", label: "Reason" },
    { id: "amount", label: "Amount" },
    { id: "username", label: "DSA Name" },
    { id: "borrower_name", label: "Dsa Disbursement Data" },
    { id: "bank_name", label: "Adjusted Disbursement Data" },  
    {id: "created_at", label: "Date"}  
  ];
  
  const getMonthString = (month: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(month, 10) - 1]; // Subtract 1 because array is zero-based
  };

  useEffect(() => {
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.STAFF]: userPermission(user, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(user, PERMISSION_TYPE.STAFF_OPS),
      [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(user, PERMISSION_TYPE.BOOKING_PARTNER),

    };
    setPermission(tempPermission);
  }, [user]);
  useEffect(() => {
    Promise.all([getUserWalletLedger()])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, []);
  const getUserWalletLedger=()=>{
    setLoader(true)
    ApiGet(`walletLedger/get-user-wallet-list?id=${id}`)
        .then((res: any) => {
          let finalData = res.data.result.map((item: any, index: number) => {
            return {
              amount: item?.amount,
              reason: item?.reason,
              _id: item?.id,
              username:item?.username,
              bank_name:item.bank_name,
              product:item.product,
              borrower_name:item.borrower_name,
              adjusted_dsadisbursement_id:item.adjusted_dsadisbursement_id,
              dsadisbursement_id:item.dsadisbursement_id,
              created_at:moment(item.created_at).format('DD-MM-YYYY h:mm:ss A')
            };
          });
          setViewData(finalData)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
  
        });
    }

    

  return (
    <div className="overflow-x-hidden p-6">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          <div className="flex flex-wrap gap-5  justify-between items-center mb-5">
            
            {/* Start:: view Modal */}
            
            <div className="md:flex w-full lg:w-[unset] items-center  md:space-x-9 space-y-5 md:space-y-0">
            <button  className="mt-2" onClick={goBack}> <i className="pi pi-arrow-circle-left" style={{ fontSize: '2.0rem' }}></i> </button>
              <h1 className="text-[22px] text-light-gray font-semibold">
              Wallet Ledger Balance
              </h1>
            </div>
            <TableComponent
              rows={viewData}
              columns={ viewDataColumns }
              state={viewDataState}
              renderColumn={(column: any) => {
                return (
                  <TableCell
                    align="center"
                    className="!bg-gray-200"
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                );
              }}
              renderRow={(row: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell align="center" component="th" scope="row">
                      {row.reason}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.amount}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                    {row.dsadisbursement_id ? `${row.borrower_name} / ${row.bank_name} / ${row.product}` : ''}        
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                    {row.adjusted_dsadisbursement_id ? `${row.borrower_name} / ${row.bank_name} / ${row.product}` : ''}        
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.created_at}
                    </TableCell>                   
                  </TableRow>
                );
              }}
            />
            {/* End:: view Modal */}
          
</div>  
        </>
      )}
    </div>
  );
};

export default WalletLedgerAdjustment;
